import { IBaseProps, arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Col, DateField, DeleteButton, Descriptions, Divider, EditButton, NumberField, Row, Show, Space, Statistic, Tag, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useList, useOne, useShow } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { RenderTimelineModal, StateButtons } from "components/common";
import { IntakeSubMenu } from "components/intake/intakeSubmenu";
import { ShowUserCard } from "components/profile/showUserCard";
import { ObjectMetadata } from "components/utils/objectMetadata";
import dayjs from "dayjs";
import { IIntake, IIntakeActivity, IIntakeMetadata } from "interfaces/intake";
import { DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION, appendToAppTitle } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const IntakeShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult } = useShow<IIntake>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data, isLoading } = queryResult;
  const { Title, Text } = Typography;
  const record = data?.data as IIntake;
  appendToAppTitle(record?.name);

  const { data: intakeActivityResult, isLoading: isIntakeActivityLoading } = useList<IIntakeActivity>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.INTAKE_ACTIVITY,
    config: {
      filters: [{
        field: "intakeId",
        operator: "eq",
        value: record?.id
      }],
      hasPagination: false
    }
  });

  const { data: entityMetadata } = useOne<IIntakeMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.INTAKE,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = entityMetadata?.data?.config;

  // Calculate the sum of effort for all activities
  const totalEffort = intakeActivityResult?.data?.reduce((acc, curr) => acc + (curr.effort || 0), 0);
  const totalEffortHours = Math.floor(totalEffort ? totalEffort : 0);
  const totalEffortMinutes = Math.ceil(((totalEffort ? totalEffort : 0) - totalEffortHours) * 60);

  // Find unique count of created by from all activities
  const totalUniqueCreatedBy = new Set(intakeActivityResult?.data?.map(e => e.createdBy)).size;

  const threeColumnLayout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 8 } };
  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 };
  let isEditEnabled = record?.stateManager?.state ? metaConfig?.editableStates?.includes(record?.stateManager?.state) : false;
  let isDeleteEnabled = record?.stateManager?.state ? metaConfig?.deletableStates?.includes(record?.stateManager?.state) : false;

  return (
    <div className="engagement">
      <Show
        isLoading={isLoading}
        headerProps={{
          title: record?.name,
          backIcon: true,
          tags: <Tag color="gold">{stringExtensions.capitalize(record?.stateManager.state) ?? "Loading"}</Tag>,
          extra: <>
            {record &&
              <CanAccess key={RESOURCE_PATH.INTAKE} resource={RESOURCE_PATH.INTAKE.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
                <Space>
                  {isEditEnabled ? <EditButton style={{ marginRight: 8 }} ></EditButton> : <></>}
                  {isDeleteEnabled ? <DeleteButton resourceNameOrRouteName={RESOURCE_PATH.INTAKE} dataProviderName={DATAPROVIDER_DELETE} recordItemId={record.id} /> : <></>}
                  <StateButtons {...record as IBaseProps}
                    resourceToAct={RESOURCE_PATH.INTAKE}
                    resourceToActId={record?.id || "0"}
                    fetchAssignedToList={true}
                    navigateToId={(record?.id || "0")}
                    resourceToNavigate={RESOURCE_PATH.INTAKE}
                  />
                  {record && <RenderTimelineModal stateManager={record.stateManager} />}
                </Space>
              </CanAccess>
            }
          </>
        }}
      >
        {/* <Space direction="vertical" size="small"> */}
        <Descriptions column={3} style={{ backgroundColor: "black" }}>
          <Descriptions.Item label="Total Activities" labelStyle={{ fontSize: 24 }}>
            <Statistic value={intakeActivityResult?.data.length} loading={isIntakeActivityLoading} />
          </Descriptions.Item>
          <Descriptions.Item label="Total Effort" labelStyle={{ fontSize: 24 }}>
            <Statistic value={(totalEffortHours?.toString().concat("h ") ?? "0").concat(totalEffortMinutes.toString().concat("m"))} loading={isIntakeActivityLoading} />
          </Descriptions.Item>
          <Descriptions.Item label="Activity Contributors" labelStyle={{ fontSize: 24 }}>
            <Statistic value={(totalUniqueCreatedBy?.toString() ?? "0")} loading={isIntakeActivityLoading} />
          </Descriptions.Item>
        </Descriptions>
        <IntakeSubMenu intakeId={record?.id.toString() || ""} selectedResource={RESOURCE_PATH.INTAKE} ></IntakeSubMenu>

        <div style={{ margin: 24 }}>
          <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>Business Group</Title>
              <Text>{record?.businessGroup}</Text>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              {record?.stateManager?.assignedTo &&
                <>
                  <Title style={{ marginTop: 15 }} level={5}>Assigned To</Title>
                  <Space wrap split={<Divider type="vertical" />} >
                    {arrayExtensions.validateArray(record?.stateManager?.assignedTo ?? []) ? record?.stateManager?.assignedTo.map((e, i) => <ShowUserCard key={`assignedTo-${i}`} id={e}></ShowUserCard>) : "None"}
                  </Space>
                </>
              }
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>Request Type</Title>
              <Text>{record?.requestType}</Text>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>Request Subtype</Title>
              <Text>{record?.requestSubtype}</Text>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>Revenue Opportunity ($)</Title>
              <NumberField
                options={{
                  currency: "USD",
                  style: "currency",
                  notation: "compact"
                }}
                title={record?.opportunity?.toString()}
                value={record?.opportunity ?? 0} />
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>Start of Revenue Realization (Month, Year)</Title>
              <Text>{record?.realizationDate && <DateField format="MMM, YYYY" value={record?.realizationDate} />}</Text>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>Revenue Realization Duration (Months)</Title>
              <Text>{record?.realizationDurationMonths}</Text>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>Request Deadline</Title>
              <Text>{dayjs(record?.requestDeadline).format("MMM, DD, YYYY")}</Text>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>SME(s)</Title>
              {record?.smes &&
                <>
                  <Space wrap split={<Divider type="vertical" />} >
                    {arrayExtensions.validateArray(record?.smes ?? []) ? record?.smes?.map((e, i) => <ShowUserCard key={`sme-${i}`} id={e}></ShowUserCard>) : "None"}
                  </Space>
                </>
              }
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>Delivery Executive</Title>
              <ShowUserCard key={`sme-${record?.deliveryExecutive}`} id={record?.deliveryExecutive}></ShowUserCard>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Title style={{ marginTop: 15 }} level={5}>Sales Executive</Title>
              <ShowUserCard key={`sme-${record?.salesExecutive}`} id={record?.salesExecutive}></ShowUserCard>
            </Col>
          </Row>
          <Divider />
          <Title style={{ marginTop: 15 }} level={5}>Problem Statement</Title>
          <div className="container" key={`para-probStmt`} data-color-mode={getAppTheme()}>
            <MDEditor.Markdown source={record?.problemStatement} />
          </div>
          <Divider></Divider>
          <Title style={{ marginTop: 15 }} level={5}>Support Needed</Title>
          <div className="container" key={`para-supNeeded`} data-color-mode={getAppTheme()}>
            <MDEditor.Markdown source={record?.supportNeeded} />
          </div>
          <Divider></Divider>
          <Title style={{ marginTop: 15 }} level={5}>Notes</Title>
          <div className="container" key={`para-notes`} data-color-mode={getAppTheme()}>
            <MDEditor.Markdown source={record?.notes} />
          </div>
          <Divider></Divider>
          <div>
            <ObjectMetadata {...record as IBaseProps}></ObjectMetadata>
          </div>
        </div>
        {/* </Space> */}
      </Show>
    </div>
  );
};