import { IBaseProps, arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Col, Collapse, Divider, Icons, Row, Space, Tooltip, Typography } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { ObjectMetadata } from "components/utils/objectMetadata";
import dayjs from "dayjs";
import { IUser } from "interfaces";
import { IPosition } from "interfaces/resourcing";
import { useState } from "react";
import { rowGutter, threeColumnLayout, twoColumnLaytout } from "scripts/layout";
import { arrayJoinString, doesExist, showDash } from "scripts/site";

export const RequestDetails: React.FC<IPosition> = (record) => {

    const { Title, Text } = Typography;
    const { Panel } = Collapse;
    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;
    const [activePanel, setActivePanel] = useState<string[]>(['basic']);
    const changeActivePanel = (key: string | string[]) => {
        if (typeof key === "string") {
            key = [key];
        }
        setActivePanel(key);
    };

    return (
        <>
            <Collapse activeKey={activePanel} expandIconPosition="end" onChange={changeActivePanel}>
                <Panel header={<b>Basic Details</b>} key="basic">
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Request State</Title>
                            <Text copyable>{stringExtensions.capitalize(record?.stateManager?.state)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Last Updated By</Title>
                            {record?.updatedBy !== undefined ? <ShowUserCard id={record?.updatedBy} /> : "---"}
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            {record && record.type === "resourcingrequest" && record.stateManager?.comments && record.stateManager?.state === "closed" &&
                                <>
                                    <Title level={5}>Request Closure Reason</Title>
                                    <Text copyable>{record?.stateManager.comments}</Text>
                                </>
                            }
                            {record && record.type === "position" &&
                                <>
                                    <Title level={5}>Published Date</Title>
                                    <Text copyable>{dayjs(record.createdAt).format("LL")}</Text>
                                </>
                            }
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Project Name</Title>
                            <Text copyable={doesExist(record?.projectName)}>{showDash(record?.projectName)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Role Name</Title>
                            <Text copyable={doesExist(record?.name)}>{showDash(record?.name)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Title</Title>
                            <Text copyable={doesExist(record?.title)}>{showDash(record?.title)}</Text>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Position Type</Title>
                            <Text copyable={doesExist(record?.positionTypeName)}>{showDash(record?.positionTypeName)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>CRM/Opportunity ID</Title>
                            <Text copyable={doesExist(record?.crmOpportunityId)}>{showDash(record?.crmOpportunityId)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>WON</Title>
                            <Text copyable={doesExist(record?.projectWON)}>{showDash(record?.projectWON)}</Text>
                        </Col>
                    </Row>
                    <br />
                    {record?.positionType === "Replacement" &&
                        <>
                            <Divider orientation="left">Additional details for Replacement position</Divider>
                            <Row gutter={rowGutter}>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title level={5}>Replacement for Employee</Title>
                                    <Text copyable={doesExist(record?.replacementForEmployeeName)}>{showDash(record?.replacementForEmployeeName)}</Text>
                                </Col>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title level={5}>Replacement for Employee ID</Title>
                                    <Text copyable={doesExist(record?.replacementForEmployeeId)}>{showDash(record?.replacementForEmployeeId)}</Text>
                                </Col>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title level={5}>Replacement reason</Title>
                                    <Text copyable={doesExist(record?.replacementReason)}>{showDash(record?.replacementReason)}</Text>
                                </Col>
                            </Row>
                            <br />
                            <Divider />
                        </>
                    }
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Billable/Non-Billable{record?.positionType === "Investment" &&
                                <Tooltip title={"Investment requests are always non-billable"}> <Icons.QuestionCircleOutlined /></Tooltip>}
                            </Title>
                            <Text copyable={doesExist(record?.billable)}>{showDash(record?.billable)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Confidence {(record?.positionType === "New-Committed" || record?.positionType === "Replacement") &&
                                <Tooltip title={"New-Committed and Replacement requests are always confirmed"}> <Icons.QuestionCircleOutlined /></Tooltip>}
                            </Title>
                            <Text copyable={doesExist(record?.confidence)}>{showDash(record?.confidence)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Number of positions {(record?.positionType === "Replacement") &&
                                <Tooltip title={"Replacement requests always have 1 position"}> <Icons.QuestionCircleOutlined /></Tooltip>}
                            </Title>
                            <Text copyable={doesExist(record?.positionCount)}>{showDash(record?.positionCount)}</Text>
                        </Col>
                    </Row>
                    <br />
                    {record?.billable === "Billable" &&
                        <>
                            <Divider orientation="left">Additional details for billable resources</Divider>
                            <Row gutter={rowGutter}>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title level={5}>Billing Type</Title>
                                    <Text copyable={doesExist(record?.billingType)}>{showDash(record?.billingType)}</Text>
                                </Col>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title level={5}>Billing Start Date</Title>
                                    <Text copyable={doesExist(record?.billingStartDate)}>{dayjs(record?.billingStartDate).format("LL")}</Text>
                                </Col>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title level={5}>Bill Rate (USD per Hour)</Title>
                                    <Text copyable={doesExist(record?.billRate)}>{showDash(record?.billRate)}</Text>
                                </Col>
                            </Row>
                            <br />
                        </>
                    }
                    <Divider orientation="left">Constraints</Divider>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Work Location Constraints</Title>
                            <Text copyable={doesExist(record?.locationConstraint)}>{showDash(record?.locationConstraint)}</Text>
                        </Col>
                        <Col className="gutter-row" {...twoColumnLaytout}>
                            <Title level={5}>Resourcing Constraints</Title>
                            <Text copyable={doesExist(record?.resourceConstraint)}>{showDash(record?.resourceConstraint)}</Text>
                        </Col>
                    </Row>
                    <br />
                </Panel>
                <Panel header={<b>Hiring Team</b>} forceRender key="aboutteam">
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Country</Title>
                            <Text copyable={doesExist(record?.country)}>{showDash(record?.country)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Location</Title>
                            <Text copyable={doesExist(record?.location)}>{showDash(record?.location)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Project Duration (In Months)</Title>
                            <Text copyable={doesExist(record?.projectDuration)}>{showDash(record?.projectDuration)}</Text>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Group</Title>
                            <Text copyable={doesExist(record?.group)}>{showDash(record?.group)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Sub Group</Title>
                            <Text copyable={doesExist(record?.subGroup)}>{showDash(record?.subGroup)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Customer Interview</Title>
                            <Text copyable={doesExist(record?.customerInterview)}>{showDash(record?.customerInterview)}</Text>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Hiring Manager</Title>
                            {record?.hiringManager !== undefined ? <ShowUserCard id={record?.hiringManager} /> : "---"}
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Hiring Manager Delegates</Title>
                            <Space wrap split={<Divider type="vertical" />} >
                                {arrayExtensions.validateArray(record?.hiringManagerDelegates ?? []) ? record?.hiringManagerDelegates?.map((e, i) => <ShowUserCard key={`trpnl-${i}`} id={e}></ShowUserCard>) : "None"}
                            </Space>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>MS Manager</Title>
                            <Text copyable={doesExist(record?.msManager)}>{showDash(record?.msManager)}</Text>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Sales Executive (BRM)</Title>
                            {record?.salesExecutive !== undefined ? <ShowUserCard id={record?.salesExecutive} /> : "---"}
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Delivery Head (L1)</Title>
                            {record?.deliveryHead !== undefined ? <ShowUserCard id={record?.deliveryHead} /> : "---"}
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Offshore Delivery Partner (L2)</Title>
                            {record?.deliveryPartnerOffshore !== undefined ? <ShowUserCard id={record?.deliveryPartnerOffshore} /> : "---"}
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Onsite Delivery Partner (L2)</Title>
                            {record?.deliveryPartnerOnsite !== undefined ? <ShowUserCard id={record?.deliveryPartnerOnsite} /> : "---"}
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>TR Interviewers</Title>
                            <Space wrap split={<Divider type="vertical" />} >
                                {arrayExtensions.validateArray(record?.trInterviewers ?? []) ? record?.trInterviewers?.map((e, i) => <ShowUserCard key={`trpnl-${i}`} id={e}></ShowUserCard>) : "None"}
                            </Space>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>MR Interviewers</Title>
                            <Space wrap split={<Divider type="vertical" />} >
                                {arrayExtensions.validateArray(record?.mrInterviewers ?? []) ? record?.mrInterviewers?.map((e, i) => <ShowUserCard key={`mrpnl-${i}`} id={e}></ShowUserCard>) : "None"}
                            </Space>
                        </Col>
                        {record?.stateManager?.state === "draft" &&
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Requestor</Title>
                                {(record?.requestor !== undefined || userObj?.id !== undefined) ? <ShowUserCard id={record?.requestor || userObj?.id} /> : "---"}
                            </Col>
                        }
                    </Row>
                    <br />
                </Panel>
                <Panel header={<b>Skills</b>} forceRender key="skills">
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Skill Category</Title>
                            <Text copyable={doesExist(record?.skillCategory)}>{showDash(record?.skillCategory)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Required Skills</Title>
                            <Space wrap split={<Divider type="vertical" />} >
                                {arrayExtensions.validateArray(record?.requiredSkills ?? []) ? <Text copyable>{arrayJoinString(record?.requiredSkills, "; ")}</Text> : "None"}
                            </Space>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Good to have skills</Title>
                            <Space wrap split={<Divider type="vertical" />} >
                                {arrayExtensions.validateArray(record?.goodToHaveSkills ?? []) ? <Text copyable>{arrayJoinString(record?.goodToHaveSkills, "; ")}</Text> : "None"}
                            </Space>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Minimum Experience Required (in Years)</Title>
                            <Text copyable={doesExist(record?.experienceRequired)}>{showDash(record?.experienceRequired)}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Keywords</Title>
                            <Space wrap split={<Divider type="vertical" />} >
                                {arrayExtensions.validateArray(record?.keywords ?? []) ? <Text copyable>{arrayJoinString(record?.keywords, "; ")}</Text> : "None"}
                            </Space>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                        </Col>
                    </Row>
                    <br />
                    <Title level={5}>Responsibilities</Title>
                    <Text copyable={doesExist(record?.responsibilities)}>{showDash(record?.responsibilities)}</Text>
                </Panel>
                {record?.stateManager?.state !== "draft" &&
                    <Panel header={<b>Staffing Section</b>} forceRender key="staffing">
                        <Row gutter={rowGutter}>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Requestor</Title>
                                {record?.requestor !== undefined ? <ShowUserCard id={record?.requestor} /> : "---"}
                            </Col>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Delivery Unit (DU)</Title>
                                <Text copyable={doesExist(record?.deliveryUnit)}>{showDash(record?.deliveryUnit)}</Text>
                            </Col>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Request ID</Title>
                                {record?.requestID ?
                                    <Text copyable>{record?.requestID}</Text>
                                    :
                                    <Text>Resourcing Team will provide once request is published for fulfillment.</Text>
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={rowGutter}>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                {record && record.type === "resourcingrequest" ?
                                    <>
                                        <Title level={5}>RGS IDs</Title>
                                        {arrayExtensions.validateArray(record?.rgsIds ?? []) ? <Text copyable>{arrayJoinString(record?.rgsIds.map(String), "; ")}</Text> : "None"}
                                    </>
                                    :
                                    <>
                                        <Title level={5}>RGS ID</Title>
                                        <Text copyable>{record.rgsId}</Text>
                                    </>
                                }
                            </Col>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Request Received Date</Title>
                                <Text copyable={doesExist(record?.requestReceivedDate)}>{dayjs(record?.requestReceivedDate).format("LL")}</Text>
                            </Col>
                        </Row>
                        <br />
                    </Panel>
                }
                {record && record.type === "position" &&
                    <Panel header={<b>Position Details</b>} forceRender key="pstndtls">
                        <Row gutter={rowGutter}>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Emp ID of selected candidate</Title>
                                <Text copyable={doesExist(record.empIDSelectedCandidate)}>{showDash(record.empIDSelectedCandidate)}</Text>
                            </Col>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Name of selected candidate</Title>
                                <Text copyable={doesExist(record.nameSelectedCandidate)}>{showDash(record.nameSelectedCandidate)}</Text>
                            </Col>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Background Screening ID</Title>
                                <Text copyable={doesExist(record.backgroundScreeningID)}>{showDash(record.backgroundScreeningID)}</Text>
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={rowGutter}>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Mapped Date</Title>
                                {record.mappedDate ? <Text copyable>{dayjs(record.mappedDate).format("LL")}</Text> : "---"}
                            </Col>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Closure Date</Title>
                                {record.closureDate ? <Text copyable>{dayjs(record.closureDate).format("LL")}</Text> : "---"}
                            </Col>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Closure Channel</Title>
                                <Text copyable={doesExist(record.closureChannel)}>{showDash(record.closureChannel)}</Text>
                            </Col>
                        </Row>
                        <Row gutter={rowGutter}>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Tentative Closure Date</Title>
                                {record.tentativeClosureDate ? <Text copyable>{dayjs(record.tentativeClosureDate).format("LL")}</Text> : "---"}
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={rowGutter}>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Title level={5}>Notes</Title>
                                <Text copyable={doesExist(record.notes)}>{showDash(record.notes)}</Text>
                            </Col>
                        </Row>
                    </Panel>
                }
            </Collapse>
            <Row gutter={rowGutter} style={{ margin: 0 }}>
                <Col className="gutter-row">
                    <Title style={{ marginTop: 20 }} level={5}>Remarks</Title>
                    <Text copyable={doesExist(record?.remarks)}>{showDash(record?.remarks)}</Text>
                </Col>

            </Row>
            <Row gutter={rowGutter} style={{ margin: 0 }}>
                <Divider></Divider>
                <Col className="gutter-row">
                    <ObjectMetadata {...record as IBaseProps}></ObjectMetadata>
                </Col>
            </Row>

        </>
    );
}